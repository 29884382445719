import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";

const language = localStorage.getItem("i18nextLng") || "uk";

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: language,
    supportedLngs: ["en", "uk", "pl", "it", "es", "de"],
    fallbackLng: "uk",
    ns: [
      "common",
      "auth",
      "main",
      "categories",
      "account",
      "homePage",
      "menu",
      "talk",
    ],
    detection: {
      order: [
        "querystring", // This ensures URL parameters are checked first
        "localStorage",
        "cookie",
        "navigator",
        "htmlTag",
        "path",
        "subdomain",
      ],
      lookupQuerystring: "lang", // This specifies the URL parameter name
      caches: ["localStorage"],
    },
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
    react: {
      useSuspense: false,
    },
    debug: false,
    load: "currentOnly",
  })
  .then(() => {
    console.log("Initialized language:", i18n.language);
  })
  .catch((error) => {
    console.error("i18n initialization error:", error);
  });

export default i18n;
