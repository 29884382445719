import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  Box,
  HStack,
  Icon,
  VStack,
  Flex,
  Divider,
  Switch,
  useBreakpointValue,
  useToast,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Image,
  Select,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { WarningIcon, CloseIcon, ChevronDownIcon } from "@chakra-ui/icons";
import useI18n from "../../hooks/useI18n";
import { useIsMobile } from "../../hooks/useIsMobile";
import { useLanguageSettings } from "../../hooks/useLanguageSettings";

import ukFlag from "../../assets/flags/ua.svg";
import enFlag from "../../assets/flags/gb.svg";
import plFlag from "../../assets/flags/pl.svg";
import deFlag from "../../assets/flags/de.svg";
import esFlag from "../../assets/flags/es.svg";
import itFlag from "../../assets/flags/it.svg";

const languageOptions = [
  { value: "en", flag: enFlag, labelKey: "guest_settings_modal.english" },
  { value: "uk", flag: ukFlag, labelKey: "guest_settings_modal.ukrainian" },
  { value: "pl", flag: plFlag, labelKey: "guest_settings_modal.polish" },
  { value: "de", flag: deFlag, labelKey: "guest_settings_modal.german" },
  { value: "es", flag: esFlag, labelKey: "guest_settings_modal.spanish" },
  { value: "it", flag: itFlag, labelKey: "guest_settings_modal.italian" },
];

interface EditModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const EditModal: React.FC<EditModalProps> = ({ isOpen, onClose }) => {
  const { t } = useI18n("auth");
  const { currentLanguage, changeLanguage } = useLanguageSettings();
  const { isMobile } = useIsMobile();
  const toast = useToast();
  const [selectedLanguage, setSelectedLanguage] = useState(currentLanguage);
  const [hasChanges, setHasChanges] = useState(false);

  const modalSize = useBreakpointValue({
    base: "full",
    md: "2xl",
  });

  const handleLanguageChange = (newLang: string) => {
    setSelectedLanguage(newLang);
    setHasChanges(newLang !== currentLanguage);
  };

  const handleSaveChanges = () => {
    changeLanguage(selectedLanguage);
    setHasChanges(false);
    toast({
      title: t("guest_settings_modal.changes_saved"),
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  // Get the currently selected language option
  const selectedLanguageOption =
    languageOptions.find((option) => option.value === selectedLanguage) ||
    languageOptions[0];

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={modalSize}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Box display="flex" alignItems="center">
            <Text fontSize="2xl" fontWeight="bold">
              {t("settings")}
            </Text>
          </Box>
        </ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <VStack spacing={6} align="stretch">
            {/* Language Section - Active */}
            <Box>
              <Text fontSize="lg" fontWeight="semibold" mb={4}>
                {t("change_interface_language")}
              </Text>
              <Flex gap={4} alignItems="center">
                <Menu>
                  <MenuButton
                    as={Button}
                    rightIcon={<ChevronDownIcon />}
                    variant="ghost"
                    bg="#f7f7f7"
                    border={"1px solid #eaeaea"}
                    _hover={{ bg: "#eaeaea" }}
                    size="lg"
                    minW="200px"
                  >
                    <Flex alignItems="center">
                      <Image
                        src={selectedLanguageOption.flag}
                        alt={`${t(selectedLanguageOption.labelKey)} flag`}
                        boxSize="20px"
                        mr={2}
                      />
                      <Text fontWeight="normal">
                        {t(selectedLanguageOption.labelKey)}
                      </Text>
                    </Flex>
                  </MenuButton>
                  <MenuList
                    minW="200px"
                    bg="white"
                    boxShadow="md"
                    borderRadius="md"
                  >
                    {languageOptions.map((option) => (
                      <MenuItem
                        key={option.value}
                        onClick={() => handleLanguageChange(option.value)}
                        bg={
                          option.value === selectedLanguage
                            ? "#f7f7f7"
                            : undefined
                        }
                        _hover={{ bg: "#f7f7f7" }}
                      >
                        <Flex alignItems="center">
                          <Image
                            src={option.flag}
                            alt={`${t(option.labelKey)} flag`}
                            boxSize="20px"
                            mr={3}
                          />
                          <Text fontSize="md">{t(option.labelKey)}</Text>
                        </Flex>
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>
                {hasChanges && (
                  <Button
                    colorScheme="teal"
                    size="lg"
                    onClick={handleSaveChanges}
                  >
                    {t("guest_settings_modal.save_changes")}
                  </Button>
                )}
              </Flex>
            </Box>

            <Divider />

            {/* Settings Section - Inactive with Overlay */}
            <Box position="relative">
              <Box
                position="absolute"
                top={10}
                left={0}
                right={0}
                bottom={0}
                backdropFilter="blur(1px)"
                zIndex={1}
                display="flex"
                alignItems="center"
                justifyContent="start"
                flexDirection="column"
                gap={4}
              >
                <VStack
                  spacing={4}
                  bg="rgba(255, 255, 255, 1)"
                  p={6}
                  borderRadius="xl"
                  boxShadow="0 0 20px rgba(0, 0, 0, 0.1)"
                >
                  <Icon as={WarningIcon} color="teal.500" boxSize={9} />
                  <VStack spacing={2}>
                    <Text
                      fontSize="2xl"
                      fontWeight="bold"
                      textAlign="center"
                      color="gray.800"
                    >
                      {t("restricted_access")}
                    </Text>
                    <Text
                      fontSize="lg"
                      color="gray.700"
                      textAlign="center"
                      maxW="400px"
                    >
                      {t("unauth_modal_text_1")}
                    </Text>
                  </VStack>
                </VStack>
              </Box>

              {/* Inactive Settings Content */}
              <VStack spacing={6} opacity={0.3} pointerEvents="none">
                <Box width="100%">
                  <Text fontSize="lg" fontWeight="semibold" mb={4}>
                    {t("guest_settings_modal.show_category_image")}
                  </Text>
                  <Switch isDisabled defaultChecked size="lg" />
                </Box>

                <Box width="100%">
                  <Text fontSize="lg" fontWeight="semibold" mb={4}>
                    {t("guest_settings_modal.column_count")}
                  </Text>
                  <Select isDisabled defaultValue="3">
                    <option value="3">3</option>
                  </Select>
                </Box>

                <Box width="100%">
                  <Text fontSize="lg" fontWeight="semibold" mb={4}>
                    {t("guest_settings_modal.eye_tracking")}
                  </Text>
                  <Switch isDisabled defaultChecked size="lg" />
                </Box>

                <Box width="100%">
                  <Text fontSize="lg" fontWeight="semibold" mb={4}>
                    {t("guest_settings_modal.focus_time")}
                  </Text>
                  <Select isDisabled defaultValue="1000">
                    <option value="1000">1000</option>
                  </Select>
                </Box>
              </VStack>
            </Box>
          </VStack>
        </ModalBody>

        <ModalFooter mt={2}>
          <Flex
            justifyContent="space-between"
            width="100%"
            px={isMobile ? 8 : 0}
            mb={isMobile ? 6 : 0}
            direction={isMobile ? "column" : "row"}
            gap={4}
          >
            <HStack
              spacing={4}
              justifyContent={isMobile ? "space-between" : "start"}
            >
              <RouterLink to="/auth/sign-in">
                <Button colorScheme="teal" size="lg" onClick={onClose}>
                  {t("sign_in")}
                </Button>
              </RouterLink>
              <RouterLink to="/auth/sign-up">
                <Button
                  colorScheme="teal"
                  variant="outline"
                  size="lg"
                  onClick={onClose}
                >
                  {t("sign_up")}
                </Button>
              </RouterLink>
            </HStack>
            <Button
              rightIcon={<CloseIcon />}
              onClick={onClose}
              colorScheme="gray"
              size="lg"
            >
              {t("close_button")}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditModal;
