// React hook to use network status
import React from "react";
import { NetworkStatus } from "../services/network-status.service";

export const useNetworkStatus = () => {
  const [isOnline, setIsOnline] = React.useState(NetworkStatus.isOnline());

  React.useEffect(() => {
    // Subscribe to network status changes
    const unsubscribe = NetworkStatus.addListener((online) => {
      setIsOnline(online);
    });

    // Cleanup on unmount
    return unsubscribe;
  }, []);

  return isOnline;
};
