import React from "react";

/**
 * Service to manage and monitor network status
 */
export class NetworkStatusService {
  private static instance: NetworkStatusService;
  private _isOnline: boolean = navigator.onLine;
  private listeners: Array<(online: boolean) => void> = [];

  private constructor() {
    // Listen for online/offline events
    window.addEventListener("online", this.handleOnline);
    window.addEventListener("offline", this.handleOffline);
  }

  /**
   * Get singleton instance
   */
  public static getInstance(): NetworkStatusService {
    if (!NetworkStatusService.instance) {
      NetworkStatusService.instance = new NetworkStatusService();
    }
    return NetworkStatusService.instance;
  }

  /**
   * Check if the device is currently online
   */
  public isOnline(): boolean {
    return this._isOnline;
  }

  /**
   * Add a listener to be notified of network status changes
   */
  public addListener(listener: (online: boolean) => void): () => void {
    this.listeners.push(listener);

    // Return a function to remove this listener
    return () => {
      this.listeners = this.listeners.filter((l) => l !== listener);
    };
  }

  /**
   * Handle becoming online
   */
  private handleOnline = () => {
    this._isOnline = true;
    this.notifyListeners();
  };

  /**
   * Handle becoming offline
   */
  private handleOffline = () => {
    this._isOnline = false;
    this.notifyListeners();
  };

  /**
   * Notify all listeners of the current status
   */
  private notifyListeners() {
    this.listeners.forEach((listener) => listener(this._isOnline));
  }

  /**
   * Clean up resources (for testing primarily)
   */
  public cleanup() {
    window.removeEventListener("online", this.handleOnline);
    window.removeEventListener("offline", this.handleOffline);
    this.listeners = [];
  }
}

// Singleton instance
export const NetworkStatus = NetworkStatusService.getInstance();
