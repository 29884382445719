import { extendTheme } from "@chakra-ui/react";
import { keyframes } from "@emotion/react";

// Define animations for reuse
export const pulseAnimation = keyframes`
  0% { opacity: 1; transform: scale(1); }
  50% { opacity: 0.7; transform: scale(1.1); }
  100% { opacity: 1; transform: scale(1); }
`;

// Only include global styles and color definitions
const eyaBrandTheme = extendTheme({
  colors: {
    brand: {
      blue: {
        50: "#EEF2F8",
        100: "#DCE5F1",
        200: "#C6D3E5",
        300: "#B0C1D9",
        400: "#9AB3CD",
        500: "#8EA5C8", // Main primary color from logobook
        600: "#7287A9",
        700: "#5C6A8B",
        800: "#465468",
        900: "#2F3A48",
      },
      green: {
        50: "#E7E9E6",
        100: "#D0D3CD",
        200: "#A2A89B",
        300: "#747D69",
        400: "#4C5340",
        500: "#2B381F", // Main secondary color from logobook
        600: "#232E19",
        700: "#1C2414",
        800: "#14190E",
        900: "#0C0F09",
      },
      light: {
        50: "#FFFFFF",
        100: "#FFFCFB",
        200: "#FFFAF7", // Main light color from logobook
        300: "#FFF7F2",
        400: "#FFF4ED",
        500: "#FFF1E8",
      },
    },
  },
  fonts: {
    heading: "Azoft Sans, 'Montserrat', 'Roboto', sans-serif",
    body: "Azoft Sans, 'Open Sans', 'Roboto', sans-serif",
  },
  // Just keep global styles
  styles: {
    global: {
      "html, body, #root": {
        backgroundColor: "#F8F9FB",
      },
    },
  },
});

export default eyaBrandTheme;
