// Map of short language codes to their full TTS-compatible codes
export const languageCodeMap: Record<string, string> = {
  en: "en-US", // English (US)
  uk: "uk-UA", // Ukrainian
  fr: "fr-FR", // French
  de: "de-DE", // German
  es: "es-ES", // Spanish
  it: "it-IT", // Italian
  pl: "pl-PL", // Polish
  ru: "ru-RU", // Russian
  // Add more languages as needed
};

/**
 * Type for voice preferences by language
 */
export interface VoicePreference {
  voiceName: string;
  gender?: "male" | "female";
  // Add other voice-specific preferences as needed
}

/**
 * Default voice preferences for each language
 */
export const defaultVoicePreferences: Record<string, VoicePreference> = {
  en: { voiceName: "Daniel", gender: "male" },
  uk: { voiceName: "Lesya", gender: "female" },
  fr: { voiceName: "Thomas", gender: "male" },
  de: { voiceName: "Anna", gender: "female" },
  es: { voiceName: "Monica", gender: "female" },
  it: { voiceName: "Alice", gender: "female" },
};

/**
 * Convert a short language code to its full TTS-compatible code
 * @param shortCode - Short language code (e.g., 'en', 'uk')
 * @param defaultCode - Default full language code to use if mapping not found
 * @returns Full language code (e.g., 'en-US', 'uk-UA')
 */
export const getFullLanguageCode = (
  shortCode: string,
  defaultCode: string = "en-US",
): string => {
  // Convert to lowercase to ensure consistent matching
  const normalizedCode = shortCode.toLowerCase();

  // Return mapped code or construct a default one
  return languageCodeMap[normalizedCode] || defaultCode;
};

/**
 * Check if a language code is supported
 * @param code - Language code to check
 * @returns boolean indicating if the language is supported
 */
export const isLanguageSupported = (code: string): boolean => {
  const normalizedCode = code.toLowerCase();
  return normalizedCode in languageCodeMap;
};

/**
 * Get the short language code from a full language code
 * @param fullCode - Full language code (e.g., 'en-US', 'uk-UA')
 * @returns Short language code (e.g., 'en', 'uk')
 */
export const getShortLanguageCode = (fullCode: string): string => {
  return fullCode.split("-")[0].toLowerCase();
};

/**
 * Get a list of all supported language codes
 * @returns Array of supported short language codes
 */
export const getSupportedLanguages = (): string[] => {
  return Object.keys(languageCodeMap);
};

/**
 * Find the best matching voice for a given language
 * @param voices - Available TTS voices
 * @param languageCode - Target language code
 * @param preferredVoiceName - Optional preferred voice name
 * @returns Best matching voice or null if none found
 */
export const findBestMatchingVoice = (
  voices: SpeechSynthesisVoice[],
  languageCode: string,
  preferredVoiceName?: string,
): SpeechSynthesisVoice | null => {
  const fullCode = getFullLanguageCode(languageCode);
  const shortCode = getShortLanguageCode(languageCode);

  // Try to find preferred voice first
  if (preferredVoiceName) {
    const preferredVoice = voices.find(
      (voice) =>
        voice.name.startsWith(preferredVoiceName) &&
        voice.lang.startsWith(shortCode),
    );
    if (preferredVoice) return preferredVoice;
  }

  // Try exact match for full language code
  const exactMatch = voices.find((voice) => voice.lang === fullCode);
  if (exactMatch) return exactMatch;

  // Try matching just the language part
  const languageMatch = voices.find((voice) =>
    voice.lang.startsWith(shortCode),
  );
  if (languageMatch) return languageMatch;

  return null;
};
