import { useCallback, useEffect, useState, useRef } from "react";
import { IUseTtsService } from "./IUseTtsService";
import {
  findBestMatchingVoice,
  getFullLanguageCode,
  getShortLanguageCode,
} from "../../utilities/languageUtil";

interface UseWebTtsServiceOptions {
  lang?: string;
  voiceName?: string;
}

const useWebTtsService = ({
  lang = "en",
  voiceName = "Daniel",
}: UseWebTtsServiceOptions): IUseTtsService & {
  isVoiceAvailable: boolean;
} => {
  const [language, setLanguage] = useState<string>(lang);
  const [preferredVoiceNames, setPreferredVoiceNames] = useState<
    Record<string, string>
  >({
    en: "Daniel",
    uk: "Lesya",
    fr: "Thomas",
    de: "Anna",
    es: "Monica",
    it: "Alice",
    pl: "Zosia",
    ru: "Milena",
  });

  const voicesRef = useRef<SpeechSynthesisVoice[]>([]);
  const [isVoiceAvailable, setIsVoiceAvailable] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  // Initialize voices and check availability
  useEffect(() => {
    const loadVoices = () => {
      try {
        const availableVoices = window.speechSynthesis.getVoices();
        voicesRef.current = availableVoices;

        // Set initial voice preference if not set yet
        if (voiceName && !preferredVoiceNames[getShortLanguageCode(lang)]) {
          setPreferredVoiceNames((prev) => ({
            ...prev,
            [getShortLanguageCode(lang)]: voiceName,
          }));
        }

        const fullLanguageCode = getFullLanguageCode(lang);
        const shortCode = getShortLanguageCode(fullLanguageCode);
        const hasVoicesForLanguage = availableVoices.some((voice) =>
          voice.lang.startsWith(shortCode),
        );

        setIsVoiceAvailable(hasVoicesForLanguage);
        setIsInitialized(true);
      } catch (error) {
        console.error("Error loading voices:", error);
        setIsVoiceAvailable(false);
      }
    };

    // Try to load voices immediately
    loadVoices();

    // Set up event listener for voices changed
    if (typeof window.speechSynthesis.onvoiceschanged !== "undefined") {
      window.speechSynthesis.onvoiceschanged = loadVoices;
    }

    return () => {
      if (typeof window.speechSynthesis.onvoiceschanged !== "undefined") {
        window.speechSynthesis.onvoiceschanged = null;
      }
    };
  }, [lang, voiceName]);

  /**
   * Clean text for TTS to improve pronunciation
   */
  const cleanTextForTTS = (text: string): string => {
    return text
      .replace(/['"''""]/g, " ")
      .replace(/[.,!?;:]/g, " ")
      .replace(/\s+/g, " ")
      .trim();
  };

  /**
   * Find the best voice for a specific language
   */
  const findVoiceForLanguage = useCallback(
    (languageCode: string): SpeechSynthesisVoice | null => {
      const shortCode = getShortLanguageCode(languageCode);
      const preferredName = preferredVoiceNames[shortCode];

      return findBestMatchingVoice(
        voicesRef.current,
        languageCode,
        preferredName,
      );
    },
    [preferredVoiceNames],
  );

  /**
   * Speak text using the configured language and voice
   */
  const speak = useCallback(
    (text: string, overrideLanguage?: string): void => {
      if (!("speechSynthesis" in window)) {
        console.error("Browser does not support Web Speech API");
        return;
      }

      if (!text || !text.trim()) return;

      const adjustedText = cleanTextForTTS(text);
      window.speechSynthesis.cancel(); // Cancel any ongoing speech

      const languageToUse = overrideLanguage || language;

      const bestVoice = findVoiceForLanguage(languageToUse);

      if (!bestVoice) {
        console.error(`No suitable voice found for language: ${language}`);
        return;
      }

      const utterance = new SpeechSynthesisUtterance(adjustedText);
      utterance.voice = bestVoice;
      utterance.lang = bestVoice.lang;

      window.speechSynthesis.speak(utterance);
    },
    [language, findVoiceForLanguage],
  );

  /**
   * Set the TTS language
   */
  const setTtsLanguage = useCallback((newLang: string) => {
    setLanguage(newLang);
  }, []);

  /**
   * Set voice name for a specific language
   */
  const setVoiceName = useCallback((lang: string, newVoiceName: string) => {
    setPreferredVoiceNames((prev) => ({
      ...prev,
      [getShortLanguageCode(lang)]: newVoiceName,
    }));
  }, []);

  /**
   * Get available voices for the current language
   */
  const getAvailableVoices = useCallback(() => {
    const fullLanguageCode = getFullLanguageCode(language);
    const shortCode = getShortLanguageCode(fullLanguageCode);
    return voicesRef.current.filter((voice) =>
      voice.lang.startsWith(shortCode),
    );
  }, [language]);

  return {
    speak,
    setTtsLanguage,
    setVoiceName,
    getAvailableVoices,
    isVoiceAvailable,
  };
};

export default useWebTtsService;
