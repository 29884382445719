import { useCallback, useState } from "react";
import { IUseTtsService } from "./IUseTtsService";
import networkAgent from "../../services/network-agent.service";
import { Howl } from "howler";
import { getFullLanguageCode } from "../../utilities/languageUtil";

interface UseBackendTtsServiceOptions {
  lang?: string;
  voiceName?: string;
}

const useBackendTtsService = ({
  lang = "en-US",
  voiceName,
}: UseBackendTtsServiceOptions): IUseTtsService => {
  const [language, setLanguage] = useState<string>(lang);
  const [preferredVoiceNames, setPreferredVoiceNames] = useState<
    Record<string, string>
  >({});

  /**
   * Speak text using the API with the currently configured language
   */
  const speak = useCallback(
    async (text: string, overrideLanguage?: string): Promise<void> => {
      if (!text?.trim()) return;

      try {
        const url = `${process.env.REACT_APP_API_URL}/tts`;
        const languageToUse = overrideLanguage || language;
        const fullLanguageCode = getFullLanguageCode(languageToUse);
        const currentVoiceName =
          preferredVoiceNames[fullLanguageCode] || voiceName;

        const response = await networkAgent.postRequest(
          url,
          {
            text,
            language: fullLanguageCode,
            voiceName: currentVoiceName,
          },
          { responseType: "blob" },
        );

        if (response.data instanceof Blob) {
          await playAudioBlob(response.data);
        }
      } catch (error) {
        console.error("Error using Backend TTS:", error);
        throw error; // Re-throw to allow fallback in parent hook
      }
    },
    [language, voiceName, preferredVoiceNames],
  );

  /**
   * Helper function to play an audio blob using Howler
   */
  const playAudioBlob = (audioBlob: Blob): Promise<void> => {
    return new Promise((resolve, reject) => {
      const audioUrl = URL.createObjectURL(audioBlob);
      const sound = new Howl({
        src: [audioUrl],
        format: ["mp3"],
        onend: () => {
          URL.revokeObjectURL(audioUrl);
          resolve();
        },
        onloaderror: () => {
          URL.revokeObjectURL(audioUrl);
          reject(new Error("Failed to load audio"));
        },
        onplayerror: () => {
          URL.revokeObjectURL(audioUrl);
          reject(new Error("Failed to play audio"));
        },
      });
      sound.play();
    });
  };

  /**
   * Set the primary TTS language
   */
  const setTtsLanguage = useCallback((newLang: string) => {
    setLanguage(newLang);
  }, []);

  /**
   * Set voice name for a specific language
   */
  const setVoiceName = useCallback((lang: string, name: string) => {
    const fullLangCode = getFullLanguageCode(lang);
    setPreferredVoiceNames((prev) => ({
      ...prev,
      [fullLangCode]: name,
    }));
  }, []);

  /**
   * Get available voices (for API consistency with webTTS)
   * Backend service doesn't have direct voice enumeration, so return empty array
   */
  const getAvailableVoices = useCallback(() => {
    return [];
  }, []);

  return {
    speak,
    setTtsLanguage,
    setVoiceName,
    getAvailableVoices,
  };
};

export default useBackendTtsService;
