import React, { useEffect, useState } from "react";
import { Box, ChakraProvider, Flex } from "@chakra-ui/react";
import { BrowserRouter, useLocation } from "react-router-dom";
import "./App.css";
import theme from "./styles/theme";
import Router from "./modules/Router";
import AppQueryClient from "./AppQueryClient";
import store, { persistedStore } from "./store";
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import Header from "./sharedComponents/Header";
import { useIsMobile } from "./hooks/useIsMobile";
import i18n from "./i18n/i18n";
import { HeaderProvider } from "./sharedComponents/HeaderContext";
import { useViewportHeight } from "./hooks/useViewPortHeight";
import { useSettings } from "./modules/accountAndSettings/hooks/useSettings";
import useAuth from "./store/auth/authState";
import { usePreventZoom } from "./hooks/usePreventZoom";
import CenterSpinner from "./sharedComponents/CenterSpinner";
import InternetConnectionAlert from "./sharedComponents/InternetConnectionAlert";
import { getFullVersion } from "./config/version";
import eyaBrandTheme from "./styles/eya-brand-theme";

const AppContent = () => {
  const { isMobile } = useIsMobile();
  const location = useLocation();
  const noHeaderRoutes = ["/auth", "/account"];
  const { settings } = useSettings();
  const { tokens } = useAuth();
  const isAuthenticated = !!tokens.accessToken;

  useViewportHeight();
  const { containerStyle, contentStyle } = usePreventZoom({
    enableTouchScroll: true,
  });

  const shouldShowHeader = !noHeaderRoutes.some((route) =>
    location.pathname.startsWith(route),
  );

  // Handle language from URL parameters
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const langParam = params.get("lang");

    if (langParam && ["en", "uk", "pl", "de", "es", "it"].includes(langParam)) {
      if (isAuthenticated && settings?.language) {
        i18n.changeLanguage(settings.language);
      } else {
        i18n.changeLanguage(langParam);
        localStorage.setItem("guest_language_preference", langParam);
      }

      // Clean up URL after setting language
      const newUrl = window.location.pathname + window.location.hash;
      window.history.replaceState({}, "", newUrl);
    } else if (isAuthenticated && settings?.language) {
      i18n.changeLanguage(settings.language);
    } else {
      const guestLanguage =
        localStorage.getItem("guest_language_preference") || "uk";
      i18n.changeLanguage(guestLanguage);
    }
  }, [isAuthenticated, settings?.language]);

  return (
    <Flex direction="column" {...containerStyle}>
      <InternetConnectionAlert />
      {shouldShowHeader && (
        <Header
          showEdit={location.pathname !== "/home"}
          bgColor={isMobile ? "white" : undefined}
        />
      )}
      <Box as="main" flex="1" {...contentStyle}>
        <Router />
      </Box>
      {/*<UpdateNotification />*/}
    </Flex>
  );
};

function App() {
  const [isI18nInitialized, setIsI18nInitialized] = useState(false);

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      const registerServiceWorker = async () => {
        try {
          const registration = await navigator.serviceWorker.register(
            `/eya-service-worker.js?v=${getFullVersion()}`,
            {
              updateViaCache: "none",
              scope: "/",
            },
          );

          // Force activate the service worker
          if (registration.waiting) {
            registration.waiting.postMessage({ type: "SKIP_WAITING" });
          }

          registration.addEventListener("activated", () => {
            // Reload once on activation to ensure clean state
            if (document.visibilityState === "visible") {
              window.location.reload();
            }
          });

          // Check for updates
          setInterval(
            () => {
              registration.update();
            },
            1000 * 60 * 60,
          ); // Check every hour
        } catch (error) {
          console.error("Service worker registration failed:", error);
        }
      };

      registerServiceWorker();
    }
  }, []);

  useEffect(() => {
    // Handle i18n initialization with timeout
    const initTimeout = setTimeout(() => {
      if (!isI18nInitialized) {
        setIsI18nInitialized(true);
        console.warn("i18n initialization timed out, proceeding anyway");
      }
    }, 3000);

    i18n.on("initialized", () => {
      clearTimeout(initTimeout);
      setIsI18nInitialized(true);
    });

    return () => {
      clearTimeout(initTimeout);
      i18n.off("initialized");
    };
  }, []);

  if (!isI18nInitialized) {
    return <CenterSpinner />;
  }

  return (
    <ChakraProvider theme={eyaBrandTheme}>
      <ReduxProvider store={store}>
        <AppQueryClient>
          <PersistGate loading={null} persistor={persistedStore}>
            <BrowserRouter>
              <HeaderProvider>
                <AppContent />
              </HeaderProvider>
            </BrowserRouter>
          </PersistGate>
        </AppQueryClient>
      </ReduxProvider>
    </ChakraProvider>
  );
}

export default App;
