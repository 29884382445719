import networkAgent from "../../../services/network-agent.service";

export const login = async (email: string, password: string) => {
  const url = process.env.REACT_APP_API_URL + "/auth/login";
  return networkAgent.postRequest(url, { email, password });
};

export const logout = async () => {
  const url = process.env.REACT_APP_API_URL + "/auth/logout";
  return networkAgent.postRequest(url, {});
};

export const signup = async (email: string, password: string) => {
  const url = process.env.REACT_APP_API_URL + "/auth/register";
  return networkAgent.postRequest(url, { email, password });
};

export const verifyEmail = async (oobCode: string) => {
  const url = process.env.REACT_APP_API_URL + "/auth/verify-email";
  return networkAgent.postRequest(url, { oobCode });
};

export const resendVerificationEmail = async (email: string) => {
  const url = process.env.REACT_APP_API_URL + "/auth/resend-email-verification";
  return networkAgent.postRequest(url, { email });
};

export const refreshToken = async (refreshToken: string) => {
  const url = process.env.REACT_APP_API_URL + "/auth/refresh-token";
  return networkAgent.postRequest(url, { refreshToken });
};

export const forgotPassword = async (email: string) => {
  const url = process.env.REACT_APP_API_URL + "/auth/forgot-password";
  return networkAgent.postRequest(url, { email });
};

export const resetPassword = async (oobCode: string, newPassword: string) => {
  const url = process.env.REACT_APP_API_URL + "/auth/reset-password";
  return networkAgent.postRequest(url, { oobCode, newPassword });
};

// Google Auth Helper
// This is just a utility function - the actual redirect happens in the GoogleSignIn component
export const getGoogleAuthUrl = (): string => {
  return `${process.env.REACT_APP_API_URL}/auth/google`;
};

/**
 * Exchange authorization code for tokens
 * This function should be added to your existing auth.service.ts file
 */
export const exchangeGoogleCode = async (code: string) => {
  const apiBaseUrl = process.env.REACT_APP_API_URL || "";
  const response = await networkAgent.postRequest(
    `${apiBaseUrl}/auth/exchange-google-code`,
    { code },
  );
  console.log('exchangeGoogleCode', response.data);
  return response.data;
};
