import { useCallback, useEffect, useState, useRef } from "react";
import useWebTtsService from "../services/tts/useWebTtsService";
import useBackendTtsService from "../services/tts/useBackendTtsService";
import { IUseTtsService } from "../services/tts/IUseTtsService";
import useHeaderState from "../store/header/headerState";
import {
  getFullLanguageCode,
  getShortLanguageCode,
} from "../utilities/languageUtil";
import { loadModule } from "cld3-asm";

// Map of CLD3 ISO 639-1 codes to our application's language codes
const ISO_TO_APP_LANG: Record<string, string> = {
  en: "en", // English
  uk: "uk", // Ukrainian
  de: "de", // German
  fr: "fr", // French
  es: "es", // Spanish
  it: "it", // Italian
  pl: "pl", // Polish
  ru: "ru", // Russian
  // Add more mappings as needed
};

export enum TtsServiceType {
  Web = "web",
  Backend = "backend",
  Auto = "auto",
}

interface UseTtsOptions {
  serviceType?: TtsServiceType;
  lang?: string;
  autoDetect?: boolean;
}

interface TtsService extends IUseTtsService {
  isVoiceAvailable?: boolean;
  autoDetectLanguage: boolean;
  setAutoDetectLanguage: (enabled: boolean) => void;
}

const useTts = ({
  serviceType = TtsServiceType.Auto,
  lang = "en-US",
  autoDetect = false,
}: UseTtsOptions): TtsService => {
  const { setIsUsingCloudTTS } = useHeaderState();
  const [primaryLang, setPrimaryLang] = useState<string>(lang);
  const [autoDetectLanguage, setAutoDetectLanguage] =
    useState<boolean>(autoDetect);
  const [isDetectorReady, setIsDetectorReady] = useState<boolean>(false);

  // Track if backend should be active
  const [isBackendActive, setIsBackendActive] = useState<boolean>(false);

  // Reference to the language detector
  const detectorRef = useRef<any>(null);

  // Cache for detected languages (optimization)
  const detectionCache = useRef<Map<string, string>>(new Map());

  // Initialize cld3 language detector if auto-detection is enabled
  useEffect(() => {
    if (!autoDetectLanguage) {
      return;
    }

    const initializeDetector = async () => {
      try {
        const cld3Module = await loadModule();
        detectorRef.current = cld3Module.create(0, 1000); // min bytes, max bytes
        setIsDetectorReady(true);
        console.log("CLD3 language detector initialized successfully");
      } catch (error) {
        console.error("Failed to initialize CLD3 language detector:", error);
      }
    };

    initializeDetector();

    // Cleanup
    return () => {
      if (detectorRef.current) {
        try {
          detectorRef.current.dispose();
          detectorRef.current = null;
          setIsDetectorReady(false);
        } catch (error) {
          console.error("Error disposing CLD3 detector:", error);
        }
      }
    };
  }, [autoDetectLanguage]);

  // Initialize both TTS services unconditionally to follow React Hooks rules
  const webTts = useWebTtsService({ lang: primaryLang });

  // We need to call this hook unconditionally, but we'll create a wrapper around it
  // that prevents actual API calls when the backend shouldn't be used
  const backendTtsBase = useBackendTtsService({ lang: primaryLang });

  // Determine if we should use the backend service
  useEffect(() => {
    const shouldUseBackend =
      serviceType === TtsServiceType.Backend ||
      (serviceType === TtsServiceType.Auto && !webTts.isVoiceAvailable);

    setIsBackendActive(shouldUseBackend);

    // Update cloud TTS status
    const isUsingCloud = shouldUseBackend || !webTts.isVoiceAvailable;
    setIsUsingCloudTTS(isUsingCloud);
  }, [serviceType, webTts.isVoiceAvailable, setIsUsingCloudTTS]);

  // Create a wrapped backend service that only makes API calls when active
  const backendTts: IUseTtsService = {
    speak: (text: string, overrideLanguage?: string) => {
      if (!isBackendActive) {
        console.log("Backend TTS is inactive, speech request ignored");
        return Promise.resolve();
      }
      return backendTtsBase.speak(text, overrideLanguage);
    },
    setTtsLanguage: (newLang: string) => {
      if (isBackendActive) {
        backendTtsBase.setTtsLanguage(newLang);
      }
    },
    setVoiceName: (lang: string, name: string) => {
      if (isBackendActive) {
        backendTtsBase.setVoiceName(lang, name);
      }
    },
    getAvailableVoices: () => {
      if (isBackendActive) {
        return backendTtsBase.getAvailableVoices();
      }
      return [];
    },
  };

  // Select which service to use based on type and availability
  const selectService = useCallback(() => {
    switch (serviceType) {
      case TtsServiceType.Web:
        return webTts;
      case TtsServiceType.Backend:
        return backendTts;
      case TtsServiceType.Auto:
        return webTts.isVoiceAvailable ? webTts : backendTts;
      default:
        return webTts.isVoiceAvailable ? webTts : backendTts;
    }
  }, [serviceType, webTts, backendTts]);

  /**
   * Detect the language of the given text
   */
  const getDetectedLanguage = useCallback(
    (text: string): string => {
      // Skip detection for very short text
      if (!text || text.trim().length < 10) {
        return getShortLanguageCode(primaryLang);
      }

      // Use cache if available (for performance)
      const cacheKey = text.trim().substring(0, 100); // Use first 100 chars as key
      if (detectionCache.current.has(cacheKey)) {
        return detectionCache.current.get(cacheKey)!;
      }

      try {
        if (!detectorRef.current || !isDetectorReady) {
          return getShortLanguageCode(primaryLang);
        }

        // Use CLD3 to detect language
        const result = detectorRef.current.findLanguage(text);

        // Extract ISO language code from result
        const langCode = result.language;
        const reliability = result.probability;

        // Only use detected language if it's reliable (probability > 0.5) and supported
        const detectedLang =
          reliability > 0.5 && ISO_TO_APP_LANG[langCode]
            ? ISO_TO_APP_LANG[langCode]
            : getShortLanguageCode(primaryLang);

        // Store in cache
        detectionCache.current.set(cacheKey, detectedLang);

        return detectedLang;
      } catch (error) {
        console.error("Language detection error:", error);
        return getShortLanguageCode(primaryLang);
      }
    },
    [primaryLang, isDetectorReady],
  );

  /**
   * Speak text with language detection if enabled
   */
  const speak = useCallback(
    (text: string): void => {
      if (!text?.trim()) return;

      try {
        const service = selectService();

        // Detect language if auto-detection is enabled
        if (autoDetectLanguage && isDetectorReady) {
          const detectedLang = getDetectedLanguage(text);
          console.log("Detected language:", detectedLang);

          // If detected language is different from current, use that language
          if (detectedLang !== getShortLanguageCode(primaryLang)) {
            const fullDetectedLang = getFullLanguageCode(detectedLang);
            const originalLang = primaryLang;

            // Set language for this utterance
            service.setTtsLanguage(fullDetectedLang);

            // Speak text
            service.speak(text, fullDetectedLang);

            // Reset to original language
            service.setTtsLanguage(originalLang);
            return;
          }
        }

        // Default: Use standard speak method with primary language
        service.speak(text);
      } catch (error) {
        console.error("TTS Error:", error);

        // Only attempt fallback if we're in auto mode and backend is active
        if (
          serviceType === TtsServiceType.Auto &&
          webTts.isVoiceAvailable &&
          isBackendActive
        ) {
          try {
            backendTts.speak(text);
          } catch (fallbackError) {
            console.error("Fallback TTS Error:", fallbackError);
          }
        }
      }
    },
    [
      autoDetectLanguage,
      isDetectorReady,
      getDetectedLanguage,
      primaryLang,
      selectService,
      serviceType,
      webTts,
      backendTts,
      isBackendActive,
    ],
  );

  /**
   * Set the primary TTS language
   */
  const setTtsLanguage = useCallback(
    (newLang: string): void => {
      setPrimaryLang(newLang);
      webTts.setTtsLanguage(newLang);
      backendTts.setTtsLanguage(newLang);
    },
    [webTts, backendTts],
  );

  /**
   * Set voice for a specific language
   */
  const setVoiceName = useCallback(
    (lang: string, name: string): void => {
      webTts.setVoiceName(lang, name);
      backendTts.setVoiceName(lang, name); // This is safe - our wrapper handles the inactive case
    },
    [webTts, backendTts],
  );

  /**
   * Get available voices
   */
  const getAvailableVoices = useCallback((): SpeechSynthesisVoice[] => {
    return webTts.getAvailableVoices();
  }, [webTts]);

  // Return the TTS service with auto-detection option
  return {
    speak,
    setTtsLanguage,
    setVoiceName,
    getAvailableVoices,
    isVoiceAvailable: webTts.isVoiceAvailable,
    autoDetectLanguage,
    setAutoDetectLanguage,
  };
};

export default useTts;
