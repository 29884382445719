import React, { useEffect, useState } from "react";
import { Alert, AlertIcon, Box, ScaleFade, useToast } from "@chakra-ui/react";
import { useIsMobile } from "../hooks/useIsMobile";
import { useNetworkStatus } from "../hooks/useNetworkStatus";
import useI18n from "../hooks/useI18n";

const TOAST_ID = "network-status-toast";

const InternetConnectionAlert = () => {
  const isOnline = useNetworkStatus();
  const { isMobile } = useIsMobile();
  const toast = useToast();
  const [wasOffline, setWasOffline] = useState(false);
  const { t } = useI18n("common");

  useEffect(() => {
    if (!isOnline) {
      setWasOffline(true);
    } else if (wasOffline) {
      // Only show toast if we were previously offline
      toast.closeAll();
      // toast({
      //   id: TOAST_ID,
      //   title: t("system.toasts.connection_restored.title"),
      //   description: t("system.toasts.connection_restored.description"),
      //   status: "success",
      //   duration: 3000,
      //   isClosable: true,
      //   position: "top",
      // });
      setWasOffline(false);
    }
  }, [isOnline, toast, wasOffline]);

  if (isOnline) return null;

  return (
    <ScaleFade initialScale={0.9} in={!isOnline}>
      <Box
        position="fixed"
        bottom={isMobile ? 4 : 6}
        right={isMobile ? 4 : 6}
        zIndex={9999}
      >
        <Alert
          status="error"
          variant="solid"
          borderRadius="md"
          py={2}
          px={4}
          width="auto"
          boxShadow="lg"
        >
          <AlertIcon />
          {t("alerts.noInternetConnection")}
        </Alert>
      </Box>
    </ScaleFade>
  );
};

export default InternetConnectionAlert;
