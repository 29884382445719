import React, { useState, useEffect } from "react";
import {
  Box,
  Switch,
  Text,
  Select,
  FormControl,
  FormLabel,
  Stack,
  Divider,
  Button,
  Flex,
  NumberInput,
  NumberInputField,
  Spinner,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  IconButton,
  useToast,
  Tooltip,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Image,
} from "@chakra-ui/react";
import { CloseIcon, InfoIcon, ChevronDownIcon } from "@chakra-ui/icons";
import useI18n from "../../../hooks/useI18n";
import { useSettings } from "../hooks/useSettings";
import { DEFAULT_SETTINGS } from "../services/localSettings.provider";
import { useIsMobile } from "../../../hooks/useIsMobile";
import VersionDisplay from "../../../sharedComponents/VersionDisplay";
import { useNavigate, useLocation } from "react-router-dom";
import useTts from "../../../hooks/useTts";
import { getPreviousPath } from "../../../utilities/navigationUtils";

// Import flag images
import ukFlag from "../../../assets/flags/ua.svg";
import enFlag from "../../../assets/flags/gb.svg";
import plFlag from "../../../assets/flags/pl.svg";
import deFlag from "../../../assets/flags/de.svg";
import esFlag from "../../../assets/flags/es.svg";
import itFlag from "../../../assets/flags/it.svg";

// Define language options with their flag images and translation keys
const languageOptions = [
  { value: "en", flag: enFlag, labelKey: "settings.english" },
  { value: "uk", flag: ukFlag, labelKey: "settings.ukrainian" },
  { value: "pl", flag: plFlag, labelKey: "settings.polish" },
  { value: "de", flag: deFlag, labelKey: "settings.german" },
  { value: "es", flag: esFlag, labelKey: "settings.spanish" },
  { value: "it", flag: itFlag, labelKey: "settings.italian" },
];

const Settings: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { settings, updateSettings, isLoading } = useSettings();
  const { t, changeLanguage } = useI18n("account");
  const [formData, setFormData] = useState(DEFAULT_SETTINGS);
  const [pendingLanguage, setPendingLanguage] = useState(
    settings?.language || "uk",
  );
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const { isMobile } = useIsMobile();
  const toast = useToast();

  // TTS with auto-detection for the toggle
  const tts = useTts({
    autoDetect: settings?.autoDetectLanguage || false,
    lang: settings?.language || "uk",
  });

  // Track auto-detect setting separately
  const [autoDetectLanguage, setAutoDetectLanguage] = useState(
    settings?.autoDetectLanguage || false,
  );

  // Navigate back to the previous page or categories as fallback
  const handleClose = () => {
    const prevPath = getPreviousPath();
    navigate(prevPath);
  };

  // Initialize form data from settings when loaded
  useEffect(() => {
    if (settings) {
      setFormData(settings);
      setPendingLanguage(settings.language);
      setAutoDetectLanguage(settings.autoDetectLanguage || false);
    }
  }, [settings]);

  // Check for unsaved changes
  useEffect(() => {
    const hasChanges =
      JSON.stringify(formData) !== JSON.stringify(settings) ||
      pendingLanguage !== settings?.language ||
      autoDetectLanguage !== (settings?.autoDetectLanguage || false);
    setUnsavedChanges(hasChanges);
  }, [formData, pendingLanguage, autoDetectLanguage, settings]);

  // Handle form field changes
  const handleChange = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>,
  ) => {
    const { name, value, type } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]:
        type === "checkbox" ? (e.target as HTMLInputElement).checked : value,
    }));

    if (name === "language") {
      setPendingLanguage(value);
    }
  };

  // Handle language change from custom dropdown
  const handleLanguageChange = (newLang: string) => {
    setPendingLanguage(newLang);
  };

  // Handle auto-detect language toggle
  const handleAutoDetectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isEnabled = e.target.checked;
    setAutoDetectLanguage(isEnabled);

    // Update TTS service right away for immediate effect
    tts.setAutoDetectLanguage(isEnabled);
  };

  // Handle numeric input changes
  const handleNumberChange = (value: string, name: string) => {
    const numValue = parseInt(value, 10) || 0;
    const validValue = Math.max(1, Math.min(6, numValue));

    setFormData((prevData) => ({
      ...prevData,
      [name]: validValue,
    }));
  };

  // Save changes to settings
  const saveChanges = () => {
    const updatedSettings = {
      ...formData,
      language: pendingLanguage,
      autoDetectLanguage: autoDetectLanguage,
    };

    updateSettings(updatedSettings);
    changeLanguage(pendingLanguage);

    toast({
      title: t("settings.toast_saved") || "Settings saved",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  // Revert changes to last saved settings
  const revertChanges = () => {
    if (settings) {
      setFormData(settings);
      setPendingLanguage(settings.language);
      setAutoDetectLanguage(settings.autoDetectLanguage || false);
      tts.setAutoDetectLanguage(settings.autoDetectLanguage || false);
    }
  };

  // Get the currently selected language option
  const selectedLanguageOption =
    languageOptions.find((option) => option.value === pendingLanguage) ||
    languageOptions[0];

  // Custom language selector component with flags
  const LanguageSelector = () => (
    <Menu>
      <MenuButton
        as={Button}
        rightIcon={<ChevronDownIcon mt={"-2px"} />}
        variant="ghost"
        bg="white"
        border={"1px solid #eaeaea"}
        _hover={{ bg: "#eaeaea" }}
        width="100%"
        height="40px"
        justifyContent="space-between"
      >
        <Flex alignItems="center">
          <Image
            src={selectedLanguageOption.flag}
            alt={`${t(selectedLanguageOption.labelKey)} flag`}
            boxSize="20px"
            mr={3}
            mt={"-3px"}
          />
          <Text fontWeight="normal">{t(selectedLanguageOption.labelKey)}</Text>
        </Flex>
      </MenuButton>
      <MenuList minW="200px" bg="white" boxShadow="md" borderRadius="md">
        {languageOptions.map((option) => (
          <MenuItem
            key={option.value}
            onClick={() => handleLanguageChange(option.value)}
            bg={option.value === pendingLanguage ? "#eaeaea" : undefined}
            _hover={{ bg: "#eaeaea" }}
          >
            <Flex alignItems="center">
              <Image
                src={option.flag}
                alt={`${t(option.labelKey)} flag`}
                boxSize="20px"
                mr={3}
              />
              <Text fontSize="md">{t(option.labelKey)}</Text>
            </Flex>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );

  // Render a form control with label and input
  const renderFormControl = (
    id: string,
    label: string,
    control: React.ReactNode,
    tooltip?: string,
  ) => (
    <FormControl>
      <Flex
        direction={isMobile ? "column" : "row"}
        align={isMobile ? "flex-start" : "center"}
        width="100%"
      >
        <FormLabel
          htmlFor={id}
          mb={isMobile ? 2 : 0}
          width={isMobile ? "100%" : "50%"}
          flexShrink={0}
        >
          {label}
          {tooltip && (
            <Tooltip label={tooltip} placement="top">
              <InfoIcon ml={1} boxSize={3} color="gray.500" />
            </Tooltip>
          )}
        </FormLabel>
        <Box width={isMobile ? "100%" : "50%"}>{control}</Box>
      </Flex>
    </FormControl>
  );

  // Show loading spinner while settings load
  if (isLoading) {
    return (
      <Flex justify="center" align="center" height="100%" width="100%">
        <Spinner size="xl" color="teal.500" thickness="4px" />
      </Flex>
    );
  }

  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      bg="white"
      position="relative"
    >
      {/* Close button (desktop only) */}
      {!isMobile && (
        <IconButton
          aria-label="Close settings"
          icon={<CloseIcon />}
          position="absolute"
          top={4}
          right={8}
          size="md"
          zIndex={10}
          colorScheme="gray"
          variant="ghost"
          _hover={{ bg: "gray.100" }}
          onClick={handleClose}
        />
      )}

      {/* Main content area */}
      <Box
        flex="1"
        overflowY="auto"
        padding={isMobile ? 4 : 8}
        pb={unsavedChanges && isMobile ? 16 : undefined}
      >
        <Text fontSize="2xl" fontWeight="bold" mb={isMobile ? 6 : 8}>
          {t("account_modal.settings")}
        </Text>

        <Stack spacing={isMobile ? 6 : 8} width="100%">
          {/* Language Settings with custom flag dropdown */}
          {renderFormControl(
            "language",
            t("settings.language"),
            <LanguageSelector />,
          )}

          {/* Auto-detect language toggle */}
          {renderFormControl(
            "autoDetectLanguage",
            t("settings.auto_detect_language") ||
              "Automatic language detection",
            <Switch
              id="autoDetectLanguage"
              name="autoDetectLanguage"
              isChecked={autoDetectLanguage}
              onChange={handleAutoDetectChange}
              size="md"
            />,
            t("settings.auto_detect_language_description") ||
              "Automatically detect and use the appropriate language for text-to-speech",
          )}

          <Divider />

          {/* Category Image Settings */}
          {renderFormControl(
            "showCategoryImage",
            t("settings.show_category_image"),
            <Switch
              id="showCategoryImage"
              name="showCategoryImage"
              isChecked={formData.showCategoryImage}
              onChange={handleChange}
              size="md"
            />,
          )}

          {/* Hide Root Categories (desktop only) */}
          {!isMobile &&
            renderFormControl(
              "hideRootCategories",
              t("settings.hide_root_categories"),
              <Switch
                id="hideRootCategories"
                name="hideRootCategories"
                isChecked={formData.hideRootCategories}
                onChange={handleChange}
                size="md"
              />,
            )}

          <Divider />

          {/* Grid Layout Settings */}
          <Box width="100%">
            <Text fontSize="lg" fontWeight="medium" mb={4}>
              {t("settings.grid_layout")}
            </Text>

            <Stack spacing={isMobile ? 6 : 4} width="100%">
              {/* Column Count */}
              {renderFormControl(
                "columnCount",
                t("settings.column_count"),
                <NumberInput
                  id="columnCount"
                  value={formData.columnCount}
                  onChange={(value) => handleNumberChange(value, "columnCount")}
                  min={1}
                  max={6}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>,
              )}

              {/* Row Count */}
              {renderFormControl(
                "rowCount",
                t("settings.row_count"),
                <NumberInput
                  id="rowCount"
                  value={formData.rowCount}
                  onChange={(value) => handleNumberChange(value, "rowCount")}
                  min={1}
                  max={6}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>,
              )}
            </Stack>
          </Box>

          {/* Save/Revert buttons (mobile) */}
          {unsavedChanges && isMobile && (
            <Flex justifyContent="space-between" mt={6} width="100%">
              <Button
                onClick={revertChanges}
                variant="outline"
                colorScheme="red"
                size="md"
                flex={1}
                mr={2}
              >
                {t("buttons.revert_changes")}
              </Button>
              <Button
                onClick={saveChanges}
                colorScheme="teal"
                size="md"
                flex={1}
                ml={2}
              >
                {t("buttons.save_changes")}
              </Button>
            </Flex>
          )}

          {/* Bottom spacing for mobile */}
          {isMobile && <Box height="60px" />}
        </Stack>
      </Box>

      {/* Save/Revert buttons (desktop) */}
      {unsavedChanges && !isMobile && (
        <Flex justifyContent="flex-end" p={8} pt={0}>
          <Button
            onClick={revertChanges}
            variant="outline"
            colorScheme="red"
            mr={4}
          >
            {t("buttons.revert_changes")}
          </Button>
          <Button onClick={saveChanges} colorScheme="teal">
            {t("buttons.save_changes")}
          </Button>
        </Flex>
      )}

      {/* Version Display */}
      <Box
        position="absolute"
        bottom={4}
        left={isMobile ? 4 : 8}
        opacity={0.7}
        zIndex={1}
      >
        <VersionDisplay />
      </Box>
    </Box>
  );
};

export default Settings;
