import { useState, useEffect, useCallback } from "react";
import { useToast } from "@chakra-ui/react";
import useI18n from "./useI18n";

export const usePWAStatus = () => {
  const [deferredPrompt, setDeferredPrompt] = useState<any>(null);
  const [isPWAInstalled, setIsPWAInstalled] = useState(false);
  const [isSafari, setIsSafari] = useState(false);
  const [isCheckingUpdate, setIsCheckingUpdate] = useState(false);
  const [updateAvailable, setUpdateAvailable] = useState(false);
  const toast = useToast();
  const { t } = useI18n("common");

  useEffect(() => {
    // Check if the app is installed (standalone mode or referrer hints)
    const checkInstalled = () => {
      const isStandalone =
        window.matchMedia("(display-mode: standalone)").matches ||
        (window.navigator as any).standalone ||
        document.referrer.includes("android-app://");
      setIsPWAInstalled(isStandalone);
    };

    // Safari detection
    const userAgent = navigator.userAgent.toLowerCase();
    setIsSafari(/safari/.test(userAgent) && !/chrome/.test(userAgent));

    checkInstalled();
    const mediaQuery = window.matchMedia("(display-mode: standalone)");
    mediaQuery.addEventListener("change", checkInstalled);

    return () => {
      mediaQuery.removeEventListener("change", checkInstalled);
    };
  }, []);

  useEffect(() => {
    // Listen for beforeinstallprompt event (not supported in Safari)
    const handler = (e: any) => {
      console.log("beforeinstallprompt event fired");
      e.preventDefault();
      setDeferredPrompt(e);
    };

    window.addEventListener("beforeinstallprompt", handler);
    return () => window.removeEventListener("beforeinstallprompt", handler);
  }, []);

  const promptInstall = async () => {
    if (!deferredPrompt) return;
    deferredPrompt.prompt();
    const { outcome } = await deferredPrompt.userChoice;

    if (outcome === "accepted") {
      console.log(t("system.toasts.pwa.installation_accepted"));
      setDeferredPrompt(null);
    } else {
      console.log(t("system.toasts.pwa.installation_dismissed"));
    }
  };

  const checkForUpdates = async () => {
    if (!("serviceWorker" in navigator)) {
      toast({
        title: t("system.toasts.update_check.not_supported.title"),
        description: t("system.toasts.update_check.not_supported.description"),
        status: "error",
        duration: 3000,
      });
      return false;
    }

    setIsCheckingUpdate(true);
    try {
      const registration = await navigator.serviceWorker.ready;

      // Force update check
      await registration.update();

      // Check if there's a new version available
      const currentVersion = registration.active?.scriptURL;
      const response = await fetch(registration.active?.scriptURL || "", {
        cache: "no-cache",
      });
      const newVersion = response.url;

      const hasUpdate = currentVersion !== newVersion;
      setUpdateAvailable(hasUpdate);

      if (hasUpdate) {
        toast({
          title: t("system.toasts.update_check.available.title"),
          description: t("system.toasts.update_check.available.description"),
          status: "info",
          duration: 5000,
          isClosable: true,
        });
      } else {
        toast({
          title: t("system.toasts.update_check.up_to_date.title"),
          description: t("system.toasts.update_check.up_to_date.description"),
          status: "success",
          duration: 3000,
        });
      }

      return hasUpdate;
    } catch (error) {
      console.error("Update check failed:", error);
      toast({
        title: t("system.toasts.update_check.error.title"),
        description: t("system.toasts.update_check.error.description"),
        status: "error",
        duration: 3000,
      });
      return false;
    } finally {
      setIsCheckingUpdate(false);
    }
  };

  const applyUpdate = () => {
    window.location.reload();
  };

  const hardReload = useCallback(() => {
    console.log("Performing hard reload...");

    // Clear cache storage (service workers & HTTP cache)
    if ("caches" in window) {
      caches.keys().then((names) => {
        names.forEach((name) => caches.delete(name));
      });
    }

    // If service workers exist, unregister them before reloading
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        registrations.forEach((registration) => registration.unregister());
      });
    }

    // Force a hard reload, ensuring fresh assets are loaded
    window.location.replace(
      `${window.location.origin}${window.location.pathname}?t=${Date.now()}`,
    );
  }, []);

  return {
    isPWAInstalled,
    isSafari,
    canInstallPWA: !isPWAInstalled && (!!deferredPrompt || isSafari),
    promptInstall,
    isCheckingUpdate,
    updateAvailable,
    checkForUpdates,
    applyUpdate: hardReload,
  };
};
