import React from "react";
import { MenuItem, Spinner } from "@chakra-ui/react";
import { RepeatIcon, DownloadIcon } from "@chakra-ui/icons";
import { usePWAStatus } from "../hooks/usePWAStatus";

export const MenuUpdateItem = ({ t }: { t: (key: string) => string }) => {
  const { isCheckingUpdate, applyUpdate } = usePWAStatus();

  const handleClick = () => {
    setTimeout(() => {
      applyUpdate();
    }, 500);
  };

  return (
    <MenuItem
      icon={isCheckingUpdate ? <Spinner size="sm" /> : <RepeatIcon />}
      onClick={handleClick}
      isDisabled={isCheckingUpdate}
    >
      {isCheckingUpdate ? t("checking_updates") : t("check_updates")}
    </MenuItem>
  );
};
