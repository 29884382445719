interface Version {
  version: string;
  buildNumber: string;
  timestamp: number;
}

// You can manually update the version
export const APP_VERSION: Version = {
  version: process.env.REACT_APP_VERSION || "1.0.0",
  buildNumber: process.env.REACT_APP_BUILD_NUMBER || "1",
  timestamp: parseInt(process.env.REACT_APP_BUILD_TIMESTAMP || "0", 10),
};

// Helper function to get full version string
export const getFullVersion = (): string => {
  return `${APP_VERSION.version} (${APP_VERSION.buildNumber})`;
};

// Helper function to get formatted build date if needed
export const getBuildDate = (): string => {
  return new Date(APP_VERSION.timestamp * 1000).toLocaleString();
};
