import { Settings } from "../models/settings.model";

const isMobile = window.innerWidth < 768;

export const DEFAULT_SETTINGS: Settings = {
  showCategoryImage: true,
  eyeTrackingMode: false,
  voiceAssistantMode: false,
  focusTime: 2000,
  wakeWord: "Eya",
  language: "uk",
  notificationsEnabled: true,
  theme: "light",
  columnCount: isMobile ? 2 : 4,
  rowCount: isMobile ? 3 : 5,
  hideRootCategories: false,
  autoDetectLanguage: true,
};

export class LocalSettingsProvider {
  private readonly SETTINGS_KEY = "eya_settings";

  private defaultSettings = DEFAULT_SETTINGS;

  constructor(defaultOverrides?: Partial<Settings>) {
    if (defaultOverrides) {
      this.defaultSettings = { ...this.defaultSettings, ...defaultOverrides };
    }
    this.initializeSettings();
  }

  private initializeSettings(): void {
    if (!localStorage.getItem(this.SETTINGS_KEY)) {
      localStorage.setItem(
        this.SETTINGS_KEY,
        JSON.stringify(this.defaultSettings),
      );
    }
  }

  async getSettings(): Promise<Settings> {
    try {
      const settingsJson = localStorage.getItem(this.SETTINGS_KEY);
      if (!settingsJson) {
        return this.defaultSettings;
      }

      const settings = JSON.parse(settingsJson);
      return { ...this.defaultSettings, ...settings };
    } catch (error) {
      console.error("Error reading settings:", error);
      return this.defaultSettings;
    }
  }

  async saveSettings(settings: Settings): Promise<void> {
    try {
      localStorage.setItem(this.SETTINGS_KEY, JSON.stringify(settings));
    } catch (error) {
      console.error("Error saving settings:", error);
      throw new Error("Failed to save settings");
    }
  }

  async updateSettings(partialSettings: Partial<Settings>): Promise<Settings> {
    const currentSettings = await this.getSettings();
    const updatedSettings = { ...currentSettings, ...partialSettings };

    console.log("updatedSettings", updatedSettings);
    await this.saveSettings(updatedSettings);
    return updatedSettings;
  }

  async resetSettings(): Promise<Settings> {
    await this.saveSettings(this.defaultSettings);
    return this.defaultSettings;
  }

  async getSetting<K extends keyof Settings>(key: K): Promise<Settings[K]> {
    const settings = await this.getSettings();
    return settings[key];
  }

  async setSetting<K extends keyof Settings>(
    key: K,
    value: Settings[K],
  ): Promise<void> {
    const settings = await this.getSettings();
    settings[key] = value;
    await this.saveSettings(settings);
  }
}
