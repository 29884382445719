/**
 * Utility functions for handling navigation and route history
 */

/**
 * Save the current path to localStorage for later navigation
 * @param {string} path - The path to save
 */
export const savePreviousPath = (path: string) => {
  // Only save actual routes, not settings routes
  if (path && !path.includes("/account")) {
    localStorage.setItem("previousPath", path);
  }
};

/**
 * Get the previously saved path or fallback to default
 * @param {string} defaultPath - The default path to return if no previous path is found
 * @returns {string} The previous path or default path
 */
export const getPreviousPath = (defaultPath = "/categories") => {
  return localStorage.getItem("previousPath") || defaultPath;
};

/**
 * Clear the stored previous path
 */
export const clearPreviousPath = () => {
  localStorage.removeItem("previousPath");
};
